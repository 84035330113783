<template src="./examMarks.html"></template>

<script>
import showStatus from "../../../NetworkManager/showStatus";
import NewExamRepository from "../../../Repository/NewExam";
import ExamSubjectAndQuestionRepository from "../../../Repository/ExamSubjectAndQuestion";
import SubjectRepository from "../../../Repository/Subject";
import SemesterRepository from "../../../Repository/SemesterUser";
import { v4 } from "uuid";
import { createMultiSheetStyledExcel, generateJsonFromExcelWithoutSchema } from "../../../utils/excel";

// Global Componenets
import CircularLoader from "../../../Components/styleGuide/Loaders/Circular.vue";
import LinearProgress from "../../../Components/styleGuide/Progress/Linear.vue";

export default {
  name: "examMarks",
  components: {
    CircularLoader,
    LinearProgress
  },
  data() {
    return {
      errorDialog: false,
      objAllStudentData: {},
      objAllStudentDataForExamSeatNo: {},
      objAllStudentDataForPRN: {},
      objAllStudentDataForRollNo: {},
      objForMarksExcel: {},
      errorArr: [],
      selectedListItem: '',
      progressIndicator1: 0,
      marksExcel: null,
      dialogForUploadMarks: false,
      examName: "",
      descriptionOfExam: "",
      backgroundColor: "#E7E7FFCF",
      calenderIcon: "mdi-calendar-month-outline",
      rules: {
        required: (value) => !!value || "This field is required",
        totalMarks: (value) =>
          /^\d*$/.test(value) || "Only numbers are allowed.",
        passingMarks: (value) =>
          /^\d*$/.test(value) || "Only numbers are allowed.",
      },
      examFormat: null,
      startDateModel: false,
      startDate: null,
      endDateModel: false,
      endDate: null,
      attainmentCheckbox: false,
      examFormCheckbox: false,
      hallTicketCheckbox: false,
      invigilationCheckbox: false,
      exam: {},
      examToEdit: null,
      examId: null,
      typStudentMarks: ['RollNo', 'PRN', 'Exam Seat No'],
      secondaryTabItems: ["Student Marks"],
      tabs1: 0,
      search: "",
      selectedSubjects: [],
      studentsSearch: "",
      createdQuestions: [],
      headers: [
        { text: "Sr No.", value: "srNo", sortable: false },
        { text: "Subject code", value: "subjectCode", sortable: false },
        { text: "Subject name", value: "subjectName", sortable: true },
        { text: "Total marks", value: "totalMarks", sortable: false },
        { text: "Passing Marks", value: "passingMarks", sortable: false },
        { text: "Student Marks", value: "studentMarks", sortable: false },
        { text: "Marking Method", value: "markingMethod", sortable: false },
        {
          text: "Total Students Passed",
          value: "passedStudents",
          sortable: false,
        },
        {
          text: "Total Students Failed",
          value: "failedStudents",
          sortable: false,
        },
      ],
      loader: false
    };
  },
  async created() {
    this.newExamRepositoryInstance = new NewExamRepository(this);
    this.subjectRepositoryInstance = new SubjectRepository(this);
    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.examSubjectAndQuestionRepositoryInstance =
      new ExamSubjectAndQuestionRepository(this);
    this.selectedDepartment =
      this.$store.getters["liveData/get_examDepartment"];
    this.selectedCourseYear =
      this.$store.getters["liveData/get_examCourseYear"];
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.selectedSemester =
      this.$store.getters["instituteData/get_selectedSemester"];
    this.examId = this.$store.getters["liveData/get_examId"];
    if (this.examId !== null) {
      this.fillExamData();
    }
    this.loader = true
    await this.fetchData();
    this.loader = false

  },
  activated() {
    // Your logic here
    let newValue = this.$store.state.liveData.examTabDependencies;
    let flag = false;
    Object.entries(newValue).forEach(([key, value]) => {
      if (value["studentMarksTab"] === true) {
        flag = true;
        value["studentMarksTab"] = false;
      }
    });
    if (flag === true) {
      this.fetchData();
    }
  },
  methods: {
    giveExcelObject(value = '', column, row, bold = false, alignment = 'center', backgroundColor = 'FFFFFF') {
      if (column && row && row > 0 && column > 0) {
        return {
          value: value,
          column: column,
          row: row,
          bold: bold,
          alignment: alignment,
          backgroundColor: backgroundColor,
          border: true
        }
      }
    },
    downloadSampleExcelForMarks() {
      const finalObj = {
        name: 'Sheet1',
        data: []
      }
      const headingColor = 'ff949494'
      const arrForExamHeading = ['Semester Name', 'Department', 'Course Year', 'Exam Title', 'Exam Type']
      const arrForSubjectHeading = ['Subject Name', 'Subject Code', 'Total Marks', 'Total Allocated Student to Exam']
      const arrForHeading = ['Roll No', 'Marks']
      arrForExamHeading.forEach((info, i) => {
        const rowForHead = i + 1
        finalObj.data.push(this.giveExcelObject(info, 1, rowForHead, true))
        if (i === 0) {
          finalObj.data.push(this.giveExcelObject(this.selectedSemester.semName, 2, rowForHead))
        }
        if (i === 1) {
          finalObj.data.push(this.giveExcelObject(this.exam.department, 2, rowForHead))
        }
        if (i === 2) {
          finalObj.data.push(this.giveExcelObject(this.exam.courseYear, 2, rowForHead))
        }
        if (i === 3) {
          finalObj.data.push(this.giveExcelObject(this.exam.title, 2, rowForHead))
        }
        if (i === 4) {
          finalObj.data.push(this.giveExcelObject(this.exam.examFormat, 2, rowForHead))
        }
      })
      if (this.selectedSubjects.length > 0) {

        this.selectedSubjects.forEach((sub, i) => {
          const col = 2 * i + 1
          arrForSubjectHeading.forEach((info, j) => {
            finalObj.data.push(this.giveExcelObject(info, col, j + 7, true))
            finalObj.data.push(this.giveExcelObject(this.selectedListItem, col, arrForSubjectHeading.length + 7, true, 'center', headingColor))
            finalObj.data.push(this.giveExcelObject('Marks', col + 1, arrForSubjectHeading.length + 7, true, 'center', headingColor))
            if (j === 0) {
              finalObj.data.push(this.giveExcelObject(sub.subjectName, col + 1, j + 7))
            }
            if (j === 1) {
              finalObj.data.push(this.giveExcelObject(sub.subjectCode, col + 1, j + 7))
            }
            if (j === 2) {
              finalObj.data.push(this.giveExcelObject(sub.totalMarks, col + 1, j + 7))
            }
            if (j === 3) {
              finalObj.data.push(this.giveExcelObject(sub.allocatedStudents.length, col + 1, j + 7))
            }
          })

        })
      }
      // createMultiSheetStyledExcel
      createMultiSheetStyledExcel([finalObj], `${this.exam.title}_${this.selectedListItem}_Marks Excel`)

    },
    validateExamDetails(ele) {
      if (ele.column === 2 && ele.row === 1) {
        if (ele.value !== this.selectedSemester.semName) {
          this.errorArr.push(`Semester name is different`)
        }
      }
      if (ele.column === 2 && ele.row === 2) {
        if (ele.value !== this.exam.department) {
          this.errorArr.push(`Department name is different`)
        }
      }
      if (ele.column === 2 && ele.row === 3) {
        if (ele.value !== this.exam.courseYear) {
          this.errorArr.push(`Course Year name is different`)
        }
      }
      if (ele.column === 2 && ele.row === 4) {
        if (ele.value !== this.exam.title) {
          this.errorArr.push(`Exam title is different`)
        }
      }
      if (ele.column === 2 && ele.row === 5) {
        if (ele.value !== this.exam.examFormat) {
          this.errorArr.push(`Exam format is different`)
        }
      }
    },
    isOdd(number) {
      return number % 2 !== 0;
    },
    async uploadMarksByExcel() {
      let arrForMarksType = ["NP", "P", "X", "NA", "F", "ABS", "Ab", "W", "NE", "X", "SNG" ,'FNF','Absent' ,'absent','AB'];
      this.dialogForUploadMarks = false
      this.errorArr = []
      this.objForMarksExcel = {}
      const arrForNumber = Array.from({ length: 50 }, (_, i) => 2 * i + 1);
      const objForSubject = []
      const arr = await generateJsonFromExcelWithoutSchema(this.marksExcel, 'flat')
      let maxRow = 0
      let maxColumn = 0
      const allExcelValue = arr.Sheet1 ? arr.Sheet1 : []
      // validate exam data and create obj from excel data
      if (allExcelValue.length > 0) {
        // get max and min count and validate exam details from excel
        allExcelValue.forEach((ele) => {
          if (ele.row > maxRow) {
            maxRow = ele.row
          }
          if (ele.column > maxColumn) {
            maxColumn = ele.column
          }
          // create one object from col and row count (minimize iteration)
          this.objForMarksExcel[`${ele.row}_${ele.column}`] = ele
          if (ele.column === 2 && ele.row < 7) {
            this.validateExamDetails(ele)
          }
        })
        // arrForNumber is array of odd value to iterate every two column to collect marks and roll for every subject
        arrForNumber.forEach((number) => {
          if (number <= maxColumn) {
            const obj = {
              subjectName: '',
              subjectCode: '',
              totalMarks: 0,
              totalAllocatedStudent: 0,
              studentDetails: []
            }
            const oneSubjectDetailsArr = []
            // this loop will create key value pair for marks and parameter
            // below 6th row for marks
            for (let j = number; j <= number + 1; j++) {
              for (let i = 6; i <= maxRow; i++) {
                const cellValue = this.objForMarksExcel[`${i}_${j}`]
                if (cellValue) {
                  if (i === 7 && !this.isOdd(j)) {
                    obj.subjectName = cellValue.value
                  }
                  if (i === 8 && !this.isOdd(j)) {
                    obj.subjectCode = cellValue.value
                  }
                  if (i === 9 && !this.isOdd(j)) {
                    obj.totalMarks = cellValue.value
                  }
                  if (i === 10 && !this.isOdd(j)) {
                    obj.totalAllocatedStudent = cellValue.value
                  }
                  if (i > 11 && !this.isOdd(j)) {
                    const lastCellValue = String(this.objForMarksExcel[`${i}_${j - 1}`].value)
                    const stuObj = {
                      parameter: lastCellValue,
                      marks: cellValue.value
                    }
                    obj.studentDetails.push(stuObj)
                  }
                }

              }
            }
            objForSubject.push(obj)
          }

        })
      } else {
        this.errorArr.push(`Sub sheet name should be Sheet1`)
      }
      if (this.selectedSubjects.length > 0) {
        this.selectedSubjects.forEach((sub, i) => {
          sub.studentDetails = []
          // create one array with for allocated student all details
          if (sub.allocatedStudents.length > 0) {
            sub.allocatedStudents.forEach((student) => {
              if (this.objAllStudentData[student]) {

                sub.studentDetails.push(this.objAllStudentData[student])
              }
            })
          }
          // validate all subject details from excel
          const values = objForSubject[i]
          if (sub.subjectName !== values.subjectName) {
            this.errorArr.push(`${values.subjectName} not valid for exam`)
          }
          if (sub.subjectCode !== values.subjectCode) {
            this.errorArr.push(`${values.subjectCode} not valid for subject`)
          }
          if (sub.totalMarks !== values.totalMarks) {
            this.errorArr.push(`${values.totalMarks} not valid for marks for ${values.subjectName}`)
          }
          if (sub.allocatedStudents.length !== values.totalAllocatedStudent) {
            this.errorArr.push(`Allocated student not valid for ${values.subjectName}`)
          }
          // validate marks and roll,prn,exam seat no
          if (values.studentDetails.length > 0) {
            values.studentDetails.forEach((user) => {

              // validate marks
              if (typeof (user.marks) === 'string') {
                if (!arrForMarksType.includes(user.marks)) {
                  this.errorArr.push(`${user.marks} not valid  marks for ${values.subjectName}`)
                }
                if (user.marks === '') {
                  this.errorArr.push(`${user.marks} not valid marks for ${values.subjectName}`)
                }
              }
              if (typeof (user.marks) === 'number') {
                if (user.marks > sub.totalMarks) {
                  this.errorArr.push(`${user.marks} exceeds total marks for ${values.subjectName}`)
                }
                if (0 > user.marks) {
                  this.errorArr.push(`${user.marks} should be grater than 0 for ${values.subjectName}`)
                }

              }
              if (user.marks === null) {
                this.errorArr.push(`${user.marks} not valid marks for ${values.subjectName}`)
              }
              if (user.marks === undefined) {
                this.errorArr.push(`${user.marks} not valid marks for ${values.subjectName}`)
              }
              // validate roll number or PRN Or Exam seat No and add uId if match
              if (user.parameter !== undefined && user.parameter !== null && user.parameter !== '' && user.parameter !== '-') {
                let flagForCheck = false
                if (sub.studentDetails.length > 0) {
                  sub.studentDetails.forEach((student) => {
                    if (this.selectedListItem === 'RollNo') {
                      if (student.rollNumber === user.parameter) {
                        if (this.objAllStudentDataForRollNo[user.parameter].uId) {
                          user.uId = this.objAllStudentDataForRollNo[user.parameter].uId
                        }
                        flagForCheck = true
                      }
                    }
                    if (this.selectedListItem === 'PRN') {
                      if (student.collegePRNNo === user.parameter) {
                        if (this.objAllStudentDataForPRN[user.parameter].uId) {
                          user.uId = this.objAllStudentDataForPRN[user.parameter].uId
                        }
                        flagForCheck = true
                      }

                    }
                    if (this.selectedListItem === 'Exam Seat No') {
                      if (student.examSeatNumber === user.parameter) {
                        if (this.objAllStudentDataForExamSeatNo[user.parameter].uId) {
                          user.uId = this.objAllStudentDataForExamSeatNo[user.parameter].uId
                        }
                        flagForCheck = true
                      }
                    }
                  })
                  if (flagForCheck === false) {
                    this.errorArr.push(`${user.parameter} is not valid ${this.selectedListItem} for subject ${values.subjectName}`)
                  }
                }
              } else {
                this.errorArr.push(`${user.parameter} not valid for subject ${values.subjectName}`)
              }
            })
          }

        })
      }

      if (this.errorArr.length > 0) {
        this.errorDialog = true
      } else {
        // now creating examMarks array to update because no validation error found
        await this.createFinalSubExamArray(objForSubject)
      }

    },

    async createFinalSubExamArray(objForSubject) {
      const arrForUpdate = []

      if (this.selectedSubjects.length > 0) {
        // for each subject creating examMarks array
        this.selectedSubjects.forEach((sub, i) => {
          sub.instituteId = this.selectedInstitute
          sub.semId = this.selectedSemester.semId
          sub.examId = this.exam.examId
          // iterating excel value collected array
          objForSubject.forEach((val) => {
            // if subject name match
            if (val.subjectName === sub.subjectName) {
              if (val.studentDetails.length > 0) {
                // iterating student details collected from excel 
                val.studentDetails.forEach((stu) => {
                  let flagForInclude = false
                  if (sub.examMarks && sub.examMarks.length > 0) {
                    sub.examMarks.forEach((marks) => {
                      if (marks.uId === stu.uId) {
                        // user exists then mark replaced
                        flagForInclude = true
                        marks.totalMarksObtained = String(stu.marks)
                        marks.answers[0] = {
                          "obtainedMarks": String(stu.marks)
                        }
                      }
                    })
                    // student not exists in examMarks array
                    if (flagForInclude === false) {
                      sub.examMarks.push({
                        uId: stu.uId,
                        totalMarksObtained: String(stu.marks),
                        answers: [{
                          "obtainedMarks": String(stu.marks)
                        }]
                      })
                    }

                  } else {
                    //  examMarks new array creation
                    sub.examMarks = [{
                      uId: stu.uId,
                      totalMarksObtained: String(stu.marks),
                      answers: [{
                        "obtainedMarks": String(stu.marks)
                      }]
                    }]
                  }

                })
              }
            }
          })
          if (sub.examMarks && sub.examMarks.length > 0) {
            arrForUpdate.push(sub)
          }
        })
      }
      if (arrForUpdate.length > 0) {
        await this.updateMultipleSubjectExam(arrForUpdate)

      }
    },
    openUploadMarksDialog(item) {
      this.dialogForUploadMarks = true
      this.selectedListItem = item;
      this.marksExcel = null

    },
    async updateMultipleSubjectExam(arr) {
      try {
        await this.examSubjectAndQuestionRepositoryInstance.updateMultipleSubjectExamMarks({
          bulkDataArray: arr
        })
        showStatus('Marks updated successfully by excel', 3000, 'success', this)

      } catch (error) {
        console.error('error', error);
        showStatus('Marks not updated by excel', 3000, 'error', this)

      }
    },
    async getStudentForCourseYear() {
      this.objAllStudentData = {}
      this.objAllStudentDataForRollNo = {}
      this.objAllStudentDataForPRN = {}
      this.objAllStudentDataForExamSeatNo = {}
      try {
        const studentData = await this.semesterRepositoryInstance.getSemUserWithNameForAttendanceAnalysis({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.exam.department,
          courseYear: this.exam.courseYear,
        })
        if (studentData.semesterUsers.length > 0) {
          studentData.semesterUsers.forEach((stu) => {
            this.objAllStudentData[stu.uId] = stu
            if (stu.rollNumber && stu.rollNumber !== '' && stu.rollNumber !== '-') {
              this.objAllStudentDataForRollNo[stu.rollNumber] = stu
            }
            if (stu.collegePRNNo && stu.collegePRNNo !== '' && stu.collegePRNNo !== '-') {
              this.objAllStudentDataForPRN[stu.collegePRNNo] = stu
            }
            if (stu.examSeatNumber && stu.examSeatNumber !== '' && stu.examSeatNumber !== '-') {
              this.objAllStudentDataForExamSeatNo[stu.collegePRNNo] = stu
            }
          })
        }
      } catch (error) {
        console.error('error', error);
      }
    },
    async fetchData() {
      await this.fetchSubjects();
      await this.getExam();
      await this.getSubjectData();
      await this.getStudentForCourseYear();
    },
    async fetchSubjects() {
      const allSubjects =
        await this.subjectRepositoryInstance.getSubjectsOfACourseYearInASemester(
          {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
          }
        );
      this.subjects = allSubjects.map((s) => {
        const { subjectCode, subjectName, subjectId } = s;
        return { subjectCode, subjectName, subjectId };
      });
    },
    async getExam() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
          examId: this.examId,
        };
        let res = await this.newExamRepositoryInstance.getExam(objToPush);
        this.exam = res.exam;
        // this.exam = res
      } catch (error) {
        console.error("error", error);
      }
    },
    async getSubjectData() {
      let response =
        await this.examSubjectAndQuestionRepositoryInstance.getExamMultipleSubjectQuestions(
          {
            instituteId: this.selectedInstitute,
            examId: this.examId,
            subjectIds: this.exam.subjectIds,
          }
        );
      let subjectsData = response.result;

      let filteredSubjects = this.subjects
        .map((subject) => {
          let dataFound = subjectsData.find(
            (d) => d?.subjectId === subject?.subjectId
          );
          if (dataFound) {
            return {
              subjectCode: subject.subjectCode,
              subjectId: subject.subjectId,
              subjectName: subject.subjectName,
              duration: dataFound.duration,
              threshold: dataFound?.threshold || "-",
              passingMarks: dataFound?.passingMarks || "-",
              totalMarks: dataFound?.totalMarks || "-",
              examDate: dataFound.examDate,
              examTime: dataFound.examTime,
              allocatedStudents: dataFound.allocatedStudents,
              allQuestions: dataFound.allQuestions,
              examMarks: dataFound.examMarks,
              isMarksGiven: dataFound.isMarksGiven,
            };
          }
          return undefined;
        })
        .filter((item) => item !== undefined);

      this.selectedSubjects = filteredSubjects;
      for (let i = 0; i < this.selectedSubjects.length; i++) {
        this.createdQuestions = this.selectedSubjects[i];
        this.getQuestionsOfSelectedSet(this.selectedSubjects[i]);
      }
    },
    async fillExamData() {
      await this.getExam();
      this.examName = this.exam.title;
      this.descriptionOfExam = this.exam.description;
      this.examFormat = this.exam.examFormat;
      this.startDate = this.exam.publishDate.split("T0")[0];
      this.endDate = this.exam.dueDate.split("T0")[0];
      this.attainmentCheckbox = this.exam.settings.isForAttainment;
      this.examFormCheckbox = this.exam.settings.isExamFormIncluded;
      this.hallTicketCheckbox = this.exam.settings.isHallTicketIncluded;
      this.invigilationCheckbox = this.exam.settings.isInvigilationIncluded;
    },
    // async fetchAssignmentQuestions() {
    //     if (this.exam !== null) {
    //         const assignmentQuestions = await this.examSubjectAndQuestionRepositoryInstance.getExamSubjectQuestions({
    //             instituteId: this.selectedInstitute,
    //             examId: this.examId,
    //             subjectId: this.prop_selectedSubjectId
    //         })
    //         this.createdQuestions = assignmentQuestions.result
    //         if (this.createdQuestions.overallCOs.length > 0 || this.createdQuestions.overallBlooms.length > 0) {
    //             this.row = 'radio-1'
    //             this.overallCosBloomsData = true
    //         }
    //         this.overallCos = this.createdQuestions.overallCOs
    //         this.overallBlooms = this.createdQuestions.overallBlooms
    //     }
    //     if (this.createdQuestions?.allQuestions.length > 0) {
    //         this.getAllSections()
    //         this.activeSet = {
    //             no: 1,
    //             setName: 'set 1'
    //         }
    //         this.setActiveSet(this.activeSet)
    //         this.createdSets = []
    //         this.setsObjectForQuestions = {}
    //         for (let i = 0; i < this.createdQuestions.allQuestions.length; i++) {
    //             if (this.createdQuestions.allQuestions[i].questionType === 'Set') {
    //                 const questionStatement = this.createdQuestions.allQuestions[i].questionStatement;
    //                 this.createdSets.push({
    //                     no: i + 1,
    //                     setName: questionStatement
    //                 })
    //                 if (!Object.prototype.hasOwnProperty.call(this.setsObjectForQuestions, questionStatement)) {
    //                     this.setsObjectForQuestions[questionStatement] = [];
    //                 }
    //                 this.setsObjectForQuestions[questionStatement].push(...this.getQuestionsOfSelectedSet({ setName: questionStatement, forShowingQuestions: true }, null));
    //             }
    //         }
    //         this.questionsArray = this.setsObjectForQuestions['set 1']
    //     }
    // },
    // getAllSections() {
    //     this.allSections = []
    //     for (let i = 0; i < this.createdQuestions?.allQuestions.length; i++) {
    //         if (this.createdQuestions?.allQuestions[i].questionType === 'Set') {
    //             for (let j = 0; j < this.createdQuestions.allQuestions[i].question.length; j++) {
    //                 this.allSections.push({ questionId: this.createdQuestions.allQuestions[i].question[j].questionId, sectionName: this.createdQuestions.allQuestions[i].question[j].questionStatement, obtainedMarks: null, questionMarks: this.createdQuestions.allQuestions[i].question[j].questionMarks })
    //             }
    //         }
    //     }
    //     for (let i = 0; i < this.students.length; i++) {
    //         this.students[i].allSections = this.allSections
    //     }
    // },
    // setActiveSet(set) {
    //     this.setsObjectForQuestions[this.activeSet.setName] = this.questionsArray
    //     this.activeSet = set;
    //     this.questionsArray = this.setsObjectForQuestions[this.activeSet.setName]
    // },
    getQuestionsOfSelectedSet(questionObject) {
      let count = 0;
      let anyQuestionFound = false;
      let setQuestions = [];
      for (let i = 0; i < this.createdQuestions.allQuestions.length; i++) {
        if (this.createdQuestions.allQuestions[i].questionType === "Set") {
          setQuestions = this.createdQuestions.allQuestions[i].question;
        }
      }
      this.seperatedSetQuestions = [];

      for (let i = 0; i < setQuestions?.length; i++) {
        anyQuestionFound = false;
        count++;
        this.seperatedSetQuestions.push({
          sectionNames: setQuestions[i].questionNumber,
          question: setQuestions[i].questionStatement,
          questionId: setQuestions[i].questionId,
          questionMarks: setQuestions[i].questionMarks,
          selectedCos: setQuestions[i]?.cos,
          cos: this.coNames,
          selectedBlooms: setQuestions[i].blooms,
          blooms: this.bloomsNames,
        });
        if (setQuestions[i].question?.length > 0) {
          const sectionQuestion = setQuestions[i].question;
          for (let j = 0; j < sectionQuestion?.length; j++) {
            if (sectionQuestion[j].questionType === "OrQuestion") {
              anyQuestionFound = false;
              this.seperatedSetQuestions.push({
                questionNumber: "OR",
              });
            }
            anyQuestionFound = false;
            count++;
            this.seperatedSetQuestions.push({
              questionNumber: sectionQuestion[j]?.questionNumber,
              question:
                sectionQuestion[j]?.questionStatement === "Solve any_out of"
                  ? "Solve any (" +
                  sectionQuestion[j].NumberOfQuestionsToSolve +
                  ")"
                  : sectionQuestion[j].questionStatement,
              tryl: sectionQuestion[j]?.NumberOfQuestionsToSolve,
              anyCount: sectionQuestion[j]?.NumberOfQuestionsToSolve,
              outOfCount: sectionQuestion[j]?.question?.length,
              solveAnyCount:
                sectionQuestion[j]?.questionStatement === "Solve any_out of"
                  ? 0
                  : sectionQuestion[j]?.NumberOfQuestionsToSolve,
              questionId: sectionQuestion[j]?.questionId,
              questionMarks: sectionQuestion[j]?.questionMarks,
              selectedCos: sectionQuestion[j]?.cos,
              cos: this.coNames,
              selectedBlooms: sectionQuestion[j]?.blooms,
              blooms: this.bloomsNames,
            });
            if (sectionQuestion[j].NumberOfQuestionsToSolve) {
              anyQuestionFound = true;
              this.seperatedSetQuestions.push({
                subQnNo: "Any " + sectionQuestion[j].NumberOfQuestionsToSolve,
                anyCount: sectionQuestion[j].NumberOfQuestionsToSolve,
                anyStatement: true,
              });
            }
            if (sectionQuestion[j]?.question?.length > 0) {
              const subQuestion = sectionQuestion[j]?.question;
              for (let k = 0; k < subQuestion.length; k++) {
                if (subQuestion[k].questionType === "OrSubQuestion") {
                  this.seperatedSetQuestions.push({
                    subQnNo: "OR",
                    anyStatement: false,
                  });
                }
                if (anyQuestionFound) {
                  count++;
                  this.seperatedSetQuestions.push({
                    subQnNo: subQuestion[k].questionNumber,
                    anySubQuestion: true,
                    anyStatement: true,
                    question: subQuestion[k].questionStatement,
                    questionId: subQuestion[k]?.questionId,
                    questionMarks: subQuestion[k].questionMarks,
                    selectedCos: subQuestion[k].cos,
                    cos: this.coNames,
                    selectedBlooms: subQuestion[k].blooms,
                    blooms: this.bloomsNames,
                  });
                } else {
                  anyQuestionFound = false;
                  count++;
                  this.seperatedSetQuestions.push({
                    subQnNo: subQuestion[k].questionNumber,
                    anyStatement: false,
                    question: subQuestion[k].questionStatement,
                    questionId: subQuestion[k]?.questionId,
                    questionMarks: subQuestion[k].questionMarks,
                    selectedCos: subQuestion[k].cos,
                    cos: this.coNames,
                    selectedBlooms: subQuestion[k].blooms,
                    blooms: this.bloomsNames,
                  });
                }
              }
            }
          }
        }
      }
      questionObject.queationsCount = count;
      // this.questionWiseMarksDialog = true
      // if (!set.forShowingQuestions && this.studentData?.answers) {
      //     const studentDataMap = new Map(this.studentData?.answers.map(data => [data.questionId, data]));
      //     for (const setQuestion of this.seperatedSetQuestions) {
      //         const studentDataForQuestion = studentDataMap.get(setQuestion.questionId);
      //         if (studentDataForQuestion) {
      //             setQuestion.assignMarks = studentDataForQuestion.obtainedMarks;
      //         }
      //     }
      //     this.questionWiseMarksDialog = true
      // } else return this.seperatedSetQuestions
    },
    goToAddMarks(item) {
      this.$router.push({
        name: "examStudentMarks",
        params: {
          // prop_department: this.prop_department,
          // prop_course: this.prop_course,
          prop_selectedSubject: item.subjectName,
          prop_selectedSubjectId: item.subjectId,
          prop_tabNumber: 5,
          prop_allSubjects: this.selectedSubjects,
          // prop_typeDivGrpBatch: this.prop_typeDivGrpBatch,
          // prop_selectedDivision: this.prop_selectedDivision,
          // prop_selectedBatch: this.prop_selectedBatch,
          // prop_selectedGroup: this.prop_selectedGroup
        },
      });
    },
  },
};
</script>

<style src="./examMarks.css"></style>