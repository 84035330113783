<template src="./examStudentMarks.html"></template>

<script>
import showStatus from "../../../NetworkManager/showStatus";
import NewExamRepository from "../../../Repository/NewExam";
import ExamSubjectAndQuestionRepository from "../../../Repository/ExamSubjectAndQuestion";
import SubjectRepository from "../../../Repository/Subject";
import SemesterUserRepository from "../../../Repository/SemesterUser";
import UserRepository from "../../../Repository/User";
import {
  createExcelThroughJsonForMultipleSheets,
  generateJsonFromExcel,
} from "../../../utils/excel";
import { v4 } from "uuid";
export default {
    name: 'examStudentMarks',
    props: [
        'prop_selectedSubject',
        'prop_selectedSubjectId',
        'prop_tabNumber',
        'prop_allSubjects'
    ],
    data() {
        return {
            blue: "#050D92",
            backgroundColor: "#E7E7FFCF",
            orange: "#FF4F1F",
            downArrow: "mdi-chevron-down",
            upArrow: "mdi-arrow-up",
            examsLoaded: true,
            isMobileView: false,
            department: 'Test Department',
            examTypes: ['Assignment'], //'Theory', 'Online'
            examType: '',
            subjectNames: [],
            secondaryTabItems: ['Question Paper'], //, 'Rubrics', 'Model Answers'
            tabs: 0,
            tabs1: 0,
            tabs2: 0,
            search: '',
            selectedTab: 0,
            examName: '',
            descriptionOfExam: '',
            totalMarks: '',
            passingMarks: '',
            thresholdMarks: '',
            dateOfPublication: null,
            dateOfCompletion: null,
            timeOfPublication: null,
            timeOfCompletion: null,
            bufferEndDate: null,
            dopmodel: false,
            docmodel: false,
            topmodel: false,
            tocmodel: false,
            btaedmodel: false,
            showCanvasDialog: false,
            canvasContainer: null,
            showAttachment: false,
            mainCard: null,
            canvas: null,
            remarkForLateSubmission: '',
            createdQuestions: null,
            startDate: "",
            fileType: [],
            allFileTypes: ['Images', 'PDF', 'Audio', 'Video'],
            menu2: false,
            calenderIcon: 'mdi-calendar-month-outline',
            attainmentCheckbox: false,
            questionsCheckbox: false,
            rubricCheckbox: false,
            selectedItem: '',
            isForQuestionsTab: false,
            switch1: false,
            setsToCreate: [],
            createdSets: [{
                no: 1,
                setName: 'set 1'
            }],
            numberOfSets: 0,
            setsObjectForQuestions: {
                'set 1': []
            },
            count: 0,
            uploadQuestionExcelDialog: false,
            uploadQuestionViseMarksViaExcel: false,
            quizQuestionExcelFile: null,
            questionMarksExcelFile: null,
            questionString: '',
            questionMarks: null,
            exam: null,
            examQuestions: [],
            isSetNameEmpty: false,
            activeSet: {
                no: 1,
                setName: 'set 1'
            },
            progressIndicator1: 0,
            loading: false,
            newTempTab: (this.questionsCheckbox || this.rubricCheckbox) ? 'Questions' : 'Model Answers',
            mainTabNames: ['Basic Details', this.newTempTab, 'Student Marks', 'Insights'],
            rules: {
                required: (value) => !!value || "This field is required",
                totalMarks: value => /^\d*$/.test(value) || 'Only numbers are allowed.',
                passingMarks: value => /^\d*$/.test(value) || 'Only numbers are allowed.'
            },
            date: '',
            subjectIds: [],
            questionsArray: [],
            examStudents: [],
            sectionDialog: false,
            sectionType: '',
            studentForQuestionWiseMarks: {},
            createSetDialog: false,
            students: [],
            openQuestionsPreview: false,
            questionDialogue: false,
            orQuestionDialogue: false,
            subQuestionDialogue: false,
            sectionNo: 0,
            questionNo: 0,
            subQuestionNo: 0,
            questionItem: '',
            allQuestionObjectArray: [],
            arrayOfQuestionsToUpdate: [],
            selectedRadio: null,
            questionToGiveMarks: [],
            allQuestions: [],
            questionsValidationSuccess: true,
            seperatedSetQuestions: [],
            allSections: [],
            studentData: {},
            questionWiseMarksDialog: false,
            nbaData: [],
            sectionsOfSelectedSet: [],
            studentMarksArrayToUpdate: [],
            subQnIndex: null,
            questionWiseMarksExcelExtraMarks: [],
            excelErrors: [],
            schema: {},
            uploadOverallMarksViaExcel: false,
            uploadSectionMarksViaExcel: false,
            overallBlooms: [],
            overallCos: [],
            selectedCoType: null,
            selectedBloomType: null,
            attachmentFiles: [],
            subjectObjectiveFileLink: [],
            // setNameForQuestionStoring: 'set 1',
            allBlooms: [
                {
                    name: "CREATE (C)",
                    shortCode: "C",
                    description:
                        "Produce new or original work (Verbs : Design, assemble, contract, conjecture, develop, formulate, author, investigate)"
                },
                {
                    name: "EVALUATE (E)",
                    shortCode: "E",
                    description:
                        "Justify a stand or decision (Verbs : appraise, argue, defend, judge, select, support, value, critique, weigh)"
                },
                {
                    name: "ANALYZE (A)",
                    shortCode: "A",
                    description:
                        "Draw connections among ideas (Verbs : differentiate, organise, relate, compare, contrast, distinguish, examine, experiment, question, test)"
                },
                {
                    name: "APPLY (Ap)",
                    shortCode: "Ap",
                    description:
                        "Use information in new situations (Verbs : execute, implement, solve, use, demonstrate, interpret, operate, schedule, sketch)"
                },
                {
                    name: "UNDERSTAND (U)",
                    shortCode: "U",
                    description:
                        "Explain ideas of concepts (Verbs : classify, describe, discuss, explain, identify, locate, recognise, report, select, translate)"
                },
                {
                    name: "REMEMBER (R)",
                    shortCode: "R",
                    description:
                        "Recall facts and basic concepts (Verbs : define, duplicate, list, memorise, repeat, state)"
                }
            ],
            bloomsNames: [],
            coNames: [],
            overallCosBloomsData: false,
            headers: [
                {
                    text: 'Sections',
                    value: 'sectionNames',
                    sortable: false,
                    width: "10%",
                    align: "center"
                },
                {
                    text: "Qn No",
                    value: "questionNumber",
                    sortable: false,
                    width: "10%",
                    align: "center"
                },
                {
                    text: 'Sub Qn No',
                    value: 'subQnNo',
                    sortable: false,
                    width: "11%",
                    align: "center"
                },
                {
                    text: 'Question Statements and Section Names   ',
                    value: 'question',
                    sortable: false,
                    width: "30%",
                    align: "center"
                },
                {
                    text: "Marks",
                    sortable: false,
                    value: "questionMarks",
                    width: "9%",
                    align: "center"
                },
                {
                    text: 'CO’s mapped',
                    value: 'cos',
                    sortable: false,
                    width: "13%",
                    align: "center"
                },
                {
                    text: 'Blooms Mapped',
                    value: 'blooms',
                    sortable: false,
                    width: "15%",
                    align: "left"
                },
                {
                    text: '',
                    value: 'delete',
                    sortable: false,
                    width: "5%",
                    align: "left"
                }
            ],
            headers1: [
                {
                    text: 'Sr No',
                    value: 'srNo',
                    width: "10%",
                    align: "center",
                    sortable: false,
                },
                {
                    text: "PRN No",
                    value: "PRN_No",
                    width: "10%",
                    align: "center",
                    sortable: false,
                },
                {
                    text: 'Roll No',
                    value: 'rollNumber',
                    width: "10%",
                    align: "center"
                },
                {
                  text: 'Exam Seat No',
                  value: 'examSeatNumber',
                  width: "10%",
                  align: "center"
                },
                {
                    text: 'Student Name',
                    value: 'name',
                    width: "auto",
                    align: "center",
                    sortable: false,
                },
                {
                    text: 'Set attempted',
                    value: 'sets',
                    sortable: false,
                    width: "20%",
                    align: "center"
                },
                // {
                //     text: 'View Submission',
                //     value: 'file',
                //     sortable: false,
                //     width: "20%",
                //     align: "center"
                // },
                // {
                //     text: 'Resubmit',
                //     value: 'resubmit',
                //     sortable: false,
                //     width: "4%",
                //     align: "center"
                // },
                // {
                //     text: 'Submission Status',
                //     value: 'submittedStatus',
                //     sortable: false,
                //     width: "14%",
                //     align: "center"
                // },
                {
                    text: "Assign Overall Marks",
                    value: "totalMarksObtained",
                    sortable: false,
                    width: "20%",
                    align: "center"
                }
            ],
            headers2: [
                {
                    text: 'Sr No',
                    value: 'srNo',
                    width: "auto",
                    align: "center",
                    sortable: false,
                },
                {
                    text: "PRN No",
                    value: "PRN_No",
                    width: "auto",
                    align: "center",
                    sortable: false,
                },
                {
                    text: 'Roll No',
                    value: 'rollNumber',
                    width: "10%",
                    align: "center"
                },
                {
                    text: 'Student Name',
                    value: 'name',
                    width: "auto",
                    align: "center",
                    sortable: false,
                },
                {
                    text: 'Set attempted',
                    value: 'sets',
                    sortable: false,
                    width: "auto%",
                    align: "center"
                },
                {
                    text: "Assign Section Wise Marks",
                    value: "allSections",
                    sortable: false,
                    width: "auto",
                    align: "center"
                },
                {
                    text: 'Total Marks',
                    value: 'totalMarksObtained',
                    sortable: false,
                    width: "auto",
                    align: "center"
                }
            ],
            headers3: [
                {
                    text: 'Sr No',
                    value: 'srNo',
                    width: "9%",
                    align: "center",
                    sortable: false,
                },
                {
                    text: "PRN No",
                    value: "PRN_No",
                    width: "9%",
                    align: "center",
                    sortable: false,
                },
                {
                    text: 'Roll No',
                    value: 'rollNumber',
                    width: "10%",
                    align: "center"
                },
                {
                    text: 'Student Name',
                    value: 'name',
                    width: "20%",
                    align: "center",
                    sortable: false,
                },
                {
                    text: 'Set attempted',
                    value: 'sets',
                    sortable: false,
                    width: "10%",
                    align: "center"
                },
                {
                    text: "Obtained Marks",
                    value: "totalMarksObtained",
                    sortable: false,
                    width: "14%",
                    align: "center"
                }
            ],
            headers4: [
                {
                    text: 'Sections',
                    value: 'sectionNames',
                    sortable: false,
                    width: "11%",
                    align: "center"
                },
                {
                    text: "Qn No",
                    value: "questionNumber",
                    sortable: false,
                    width: "7%",
                    align: "center"
                },
                {
                    text: 'Sub Qn No',
                    value: 'subQnNo',
                    sortable: false,
                    width: "8%",
                    align: "center"
                },
                {
                    text: 'Question Statements and Section Names   ',
                    value: 'question',
                    sortable: false,
                    width: "40%",
                    align: "center"
                },
                {
                    text: "Marks",
                    value: "questionMarks",
                    width: "7%",
                    align: "center"
                },
                {
                    text: 'Assign Marks',
                    value: 'assignMarks',
                    sortable: false,
                    width: "10%",
                    align: "left"
                }
            ],
            examId: null,
            isDirectMarksEditDisabled: false,
            primaryColor: "#050D92",
        }
    },
    async created() {
        for (let i = 0; i < this.allBlooms?.length; i++) {
            this.bloomsNames.push(this.allBlooms[i].name)
        }
        this.newExamRepositoryInstance = new NewExamRepository(this);
        this.subjectRepositoryInstance = new SubjectRepository(this);
        this.examSubjectAndQuestionRepositoryInstance = new ExamSubjectAndQuestionRepository(this);
        this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
        this.userRepositoryInstance = new UserRepository(this);
        this.selectedDepartment = this.$store.getters["liveData/get_examDepartment"];
        this.selectedCourseYear = this.$store.getters["liveData/get_examCourseYear"];
        this.selectedInstitute = this.$store.getters["instituteData/get_selectedInstitute"];
        this.selectedSemester = this.$store.getters["instituteData/get_selectedSemester"];
        this.examId = this.$store.getters["liveData/get_examId"];
        await this.fetchData()

    },
    methods: {
        async fetchData() {
            await this.getExam();
            await this.getSubjectData();
            await this.fetchAssignmentQuestions()
        },
        async getExam() {
            try {
                const objToPush = {
                    instituteId: this.selectedInstitute,
                    semId: this.selectedSemester.semId,
                    department: this.selectedDepartment,
                    courseYear: this.selectedCourseYear,
                    examId: this.examId,
                }
                let res = await this.newExamRepositoryInstance.getExam(objToPush)
                this.exam = res.exam;
            } catch (error) {
                console.error("error", error)
            }
        },
        displayedValue(item, data) {
            if (item[data.sectionName]) return item[data.sectionName]
            else if (item && item.answers && data) {
                for (let i = 0; i < item.answers.length; i++) {
                    if (item.answers[i].questionId === data.questionId) {
                        return item.answers[i]?.obtainedMarks;
                    }
                }
            }
        },
        async getSubjectData() {

            let response = await this.examSubjectAndQuestionRepositoryInstance.getExamMultipleSubjectQuestions({
                instituteId: this.selectedInstitute,
                examId: this.examId,
                subjectIds: this.exam.subjectIds
            })
            this.selectedSubjects = response.result;
            let subject = this.selectedSubjects.find((subject) => subject.subjectId === this.prop_selectedSubjectId)
            this.totalMarks = subject.totalMarks
            this.examStudents = subject.examMarks
        },
        async StudentMarks() {
            try {
              if (this.marksUpdated) {
                    this.marksUpdated = false
                    await this.examSubjectAndQuestionRepositoryInstance.createExamSubjectQuestions(
                        this.createdQuestions
                    );
                    showStatus("Marks updated successfully", 2000, 'success', this);
                    await this.fetchData()
                }
                const data =
                    await this.semesterUserRepositoryInstance.getSemesterUsersOfASubject({
                        instituteId: this.selectedInstitute,
                        semId: this.selectedSemester.semId,
                        subjectId: this.prop_selectedSubjectId
                    });
                let subject = this.selectedSubjects.find((subject) => subject.subjectId === this.prop_selectedSubjectId)
                this.students = data.filter((data)=>subject.allocatedStudents.includes(data.uId))
                await this.calculateStudent(this.examStudents)
             
            } catch (err) {
                console.error(err);
                this.examStudents = []
                this.calculateStudent(this.examStudents)
            }
        },
        async calculateStudent(examStudents) {
            for (let i = 0; i < this.students.length; i++) {
                let assignmentUserDocs = []
                examStudents.map((item) => {
                    if(item.uId) {
                        if(item.uId === this.students[i].uId) {
                            assignmentUserDocs.push(item)
                        }
                    }
                })
                const assignmentUser = assignmentUserDocs[0];

        if (assignmentUser) {
          let tempAnsMarks = 0;
          if (assignmentUser.questions?.length > 0) {
            for (const question of assignmentUser.questions) {
              tempAnsMarks += +question.obtainedMarks || 0;
            }
            this.students[i].totalMarksObtained = tempAnsMarks;
          } else {
            this.students[i].totalMarksObtained =
              assignmentUser.totalMarksObtained ?? assignmentUser.obtainedMarks ?? 0;
          }
          this.totalSubmissions++;
          this.students[i].answers = assignmentUser.answers;
        } else {
          this.students[i].totalMarksObtained = 0;
        }
      }

      const temp = this.students;
      this.students = temp;
      // const batchSize = 100;
      // const promises = [];
      // for (let i = 0; i < this.students.length; i += batchSize) {
      //   promises.push(
      //     this.getFullNameOfUser(
      //       i,
      //       Math.min(i + batchSize, this.students.length)
      // )
      //       );
      // }
      // await Promise.allSettled(promises);
          await this.getFullNameOfUser(
            0,
            this.students.length
          )
      this.students = [...this.students];
    },

    async getFullNameOfUser(firstIndex, lastIndex) {
      const sets = [];
      await this.fetchAssignmentQuestions();
      for (let i = 0; i < this.createdQuestions?.allQuestions.length; i++) {
        if (this.createdQuestions.allQuestions[i].questionType === "Set") {
          sets.push({
            questionId: this.createdQuestions.allQuestions[i].questionId,
            setName: this.createdQuestions.allQuestions[i].questionStatement,
          });
        }
      }
      try {
        const userObject = {
          uIds: [],
        };

        for (let i = firstIndex; i < lastIndex; i++) {
          userObject.uIds.push(this.students[i].uId);
        }

        const fullNames = await this.userRepositoryInstance.getFullNameOfUsers(
          userObject
        );

        for (let i = firstIndex; i < lastIndex; i++) {
          const { fullName, collegePRNNo } =
            fullNames.find(({ uId }) => uId === this.students[i].uId) || {};
          this.students[i].name = fullName || "";
          this.students[i].PRN_No = collegePRNNo || "";
          this.students[i].sets = sets;
          if (this.students[i].submittedOn) {
            const submittedDate = new Date(
              this.students[i].submittedOn.split("T")[0]
            );

            if (
              submittedDate > new Date(this.dateOfCompletion) &&
              this.students[i].status !== "Not Submitted"
            ) {
              this.students[i].submittedStatus =
                "Late Submitted on " +
                this.students[i].submittedOn.split("T")[0];
              this.students[i].resubmit = true;
            } else {
              if (this.students[i].status !== "Not Submitted") {
                this.students[i].submittedStatus =
                  "Submitted on " + this.students[i].submittedOn.split("T")[0];
                this.students[i].resubmit = true;
              }
            }
          } else {
            this.students[i].submittedStatus = "Not Submitted";
            this.students[i].resubmit = false;
          }
        }
        this.students = this.students.sort((a, b) =>
          `${a.PRN_No}${a.examSeatNumber}`.localeCompare(`${b.PRN_No}${b.examSeatNumber}`)
        );
        for (let i = 0; i < this.students.length; i++) {
          this.students[i].srNo = i + 1;
        }
      } catch (err) {
        console.error(err);
      }
    },
    rowClass(item) {
      if (item.sectionNames) {
        return "sectionBackgrond";
      }
    },
    fieldBackgroundColors(item) {
      if (
        item.questionNumber === "OR" ||
        item.subQnNo === "OR" ||
        item.questionNumberAny
      ) {
      } else {
        return "#9205050D";
      }
    },
    async fetchAssignmentQuestions() {
      if (this.exam !== null) {
        const assignmentQuestions =
          await this.examSubjectAndQuestionRepositoryInstance.getExamSubjectQuestions(
            {
              instituteId: this.selectedInstitute,
              examId: this.examId,
              subjectId: this.prop_selectedSubjectId,
            }
          );
        this.createdQuestions = assignmentQuestions.result;
        if (
          this.createdQuestions.overallCOs?.length > 0 ||
          this.createdQuestions.overallBlooms?.length > 0
        ) {
          this.row = "radio-1";
          this.overallCosBloomsData = true;
        }
        this.overallCos = this.createdQuestions.overallCOs;
        this.overallBlooms = this.createdQuestions.overallBlooms;
      }
      if (this.createdQuestions?.allQuestions?.length > 0) {
        this.getAllSections();
        this.activeSet = {
          no: 1,
          setName: "set 1",
        };
        this.setActiveSet(this.activeSet);
        this.createdSets = [];
        this.setsObjectForQuestions = {};
        for (let i = 0; i < this.createdQuestions.allQuestions?.length; i++) {
          if (this.createdQuestions.allQuestions[i].questionType === "Set") {
            const questionStatement =
              this.createdQuestions.allQuestions[i].questionStatement;
            this.createdSets.push({
              no: i + 1,
              setName: questionStatement,
            });
            if (
              !Object.prototype.hasOwnProperty.call(
                this.setsObjectForQuestions,
                questionStatement
              )
            ) {
              this.setsObjectForQuestions[questionStatement] = [];
            }
            this.setsObjectForQuestions[questionStatement].push(
              ...this.getQuestionsOfSelectedSet(
                { setName: questionStatement, forShowingQuestions: true },
                null
              )
            );
          }
        }
        this.questionsArray = this.setsObjectForQuestions["set 1"];
      }
      this.defaultSetSelections();
    },
    defaultSetSelections() {
      if (this.createdQuestions.allQuestions.length == 1) {
        this.students.forEach(
          (student) => (student["selectedSet"] = this.activeSet)
        );
        this.getSetSections(this.activeSet);
      }
    },
    exportOverallMarksReport() {
      const data = [];
      const sets = Object.keys(this.setsObjectForQuestions);
      for (let i = 0; i < sets?.length; i++) {
        data.push({ [sets[i]]: [] });
        for (let j = 0; j < this.students?.length; j++) {
          const objToPush = {};
          objToPush.PRN = this.students[j]?.PRN_No;
          objToPush.Name = this.students[j]?.name;
          objToPush[`Overall_Marks(${this.totalMarks})`] =
            this.students[j]?.totalMarksObtained === 0
              ? ""
              : this.students[j]?.totalMarksObtained;
          data[i][sets[i]].push(objToPush);
        }
      }
      createExcelThroughJsonForMultipleSheets(
        data,
        this.exam.title + "_" + this.prop_selectedSubject + "_Marks"
      );
    },
    downloadSectionlMarksReport() {
      const data = [];
      const sets = Object.keys(this.setsObjectForQuestions);
      for (let i = 0; i < sets?.length; i++) {
        data.push({ [sets[i]]: [] });

                for (let k = 0; k < this.students?.length; k++) {
                    const objToPush = {
                        RollNo: this.students[k]?.rollNumber,
                        // ExamSeatNo: this.students[k]?.examSeatNumber,
                        PRN: this.students[k]?.PRN_No,
                        Name: this.students[k]?.name
                    };
                    if(this.isStmiras) {
                      objToPush.ExamSeatNo = this.students[k]?.examSeatNumber
                    }

          let sectionName = "";
          for (
            let j = 0;
            j < this.setsObjectForQuestions[sets[i]]?.length;
            j++
          ) {
            if (this.setsObjectForQuestions[sets[i]][j].sectionNames) {
              sectionName =
                this.setsObjectForQuestions[sets[i]][j].sectionNames;
              const questionMark =
                this.setsObjectForQuestions[sets[i]][j].questionMarks;
              let colValue = "";

              for (let y = 0; y < this.students[k].answers?.length; y++) {
                if (
                  this.students[k].answers[y].questionId ===
                  this.setsObjectForQuestions[sets[i]][j].questionId
                ) {
                  colValue = Number(this.students[k].answers[y].obtainedMarks);
                }
              }

              objToPush[`${sectionName}(${questionMark})`] = colValue;
            }
          }

          data[i][sets[i]].push(objToPush);
        }
      }
      createExcelThroughJsonForMultipleSheets(data, this.exam.title + "_Marks");
    },
    downloadQuestionMarksReport() {
      const data = [];
      const sets = Object.keys(this.setsObjectForQuestions);
      for (let i = 0; i < sets.length; i++) {
        data.push({ [sets[i]]: [] });

                for (let k = 0; k < this.students.length; k++) {
                    const objToPush = {
                        RollNo: this.students[k]?.rollNumber,
                        // ExamSeatNo: this.students[k]?.examSeatNumber,
                        PRN: this.students[k]?.PRN_No,
                        Name: this.students[k]?.name
                    };
                    if(this.isStmiras) {
                      objToPush.ExamSeatNo = this.students[k]?.examSeatNumber
                    }

          let sectionName = "";
          for (
            let j = 0;
            j < this.setsObjectForQuestions[sets[i]].length;
            j++
          ) {
            let qNo = "";

            if (this.setsObjectForQuestions[sets[i]][j].sectionNames) {
              sectionName =
                this.setsObjectForQuestions[sets[i]][j].sectionNames;
              const questionMark =
                this.setsObjectForQuestions[sets[i]][j].questionMarks;
              let colValue = "";

              for (let y = 0; y < this.students[k].answers?.length; y++) {
                if (
                  this.students[k].answers[y].questionId ===
                  this.setsObjectForQuestions[sets[i]][j].questionId
                ) {
                  colValue = Number(this.students[k].answers[y].obtainedMarks);
                }
              }

              objToPush[`${sectionName}(${questionMark})`] = colValue;
            }

            if (
              this.setsObjectForQuestions[sets[i]][j].questionNumber &&
              this.setsObjectForQuestions[sets[i]][j].questionNumber !== "OR"
            ) {
              qNo = this.setsObjectForQuestions[sets[i]][j].questionNumber;
              const questionMark =
                this.setsObjectForQuestions[sets[i]][j].questionMarks;
              let colValue = "";

              for (let y = 0; y < this.students[k].answers?.length; y++) {
                if (
                  this.students[k].answers[y].questionId ===
                  this.setsObjectForQuestions[sets[i]][j].questionId
                ) {
                  colValue = Number(this.students[k].answers[y].obtainedMarks);
                }
              }

              objToPush[`${sectionName}_${qNo}(${questionMark})`] = colValue;
            }

            if (
              this.setsObjectForQuestions[sets[i]][j].subQnNo &&
              this.setsObjectForQuestions[sets[i]][j].subQnNo !== "OR" &&
              (!this.setsObjectForQuestions[sets[i]][j].anyStatement ||
                (this.setsObjectForQuestions[sets[i]][j].anyStatement &&
                  this.setsObjectForQuestions[sets[i]][j].question))
            ) {
              const questionMark =
                this.setsObjectForQuestions[sets[i]][j].questionMarks;
              let colValue = "";

              for (let y = 0; y < this.students[k].answers?.length; y++) {
                if (
                  this.students[k].answers[y].questionId ===
                  this.setsObjectForQuestions[sets[i]][j].questionId
                ) {
                  colValue = Number(this.students[k].answers[y].obtainedMarks);
                }
              }

              objToPush[
                `${sectionName}_${
                  this.setsObjectForQuestions[sets[i]][j].subQnNo
                }(${questionMark})`
              ] = colValue;
            }
          }

                    data[i][sets[i]].push(objToPush);
                }
            }
            createExcelThroughJsonForMultipleSheets(data, this.exam.title + '_Marks')
        },
        async startUploadingQuestionMarksExcel() {
            this.schema = {}
            this.loading = true
            const data = []
            this.excelErrors = []
            const sets = Object.keys(this.setsObjectForQuestions)
            for (let i = 0; i < sets.length; i++) {
                data.push({ [sets[i]]: [] })
                this.schema[sets[i]] = {
                    RollNo: {
                        validation: {
                        type: 'textLength'
                        },
                        variableName: 'rollNumber'
                    },
                    // ExamSeatNo: {
                    //     validation: {
                    //     type: 'textLength'
                    //     },
                    //     variableName: 'examSeatNumber'
                    // },
                    PRN: {
                        validation: {
                            type: 'textLength'
                        },
                        variableName: 'prn'
                    },
                    Name: {
                        validation: {
                            type: 'textLength',
                            required: true
                        },
                        variableName: 'name'
                    }
                }
                if(this.isStmiras) {
                  this.schema[sets[i]].ExamSeatNo = {
                        validation: {
                        type: 'textLength'
                        },
                        variableName: 'examSeatNumber'
                    }
                }
                for (let k = 0; k < this.students.length; k++) {
                    data[i][sets[i]].push({ PRN: this.students[k].PRN_No, Name: this.students[k].name })
                }
                let sectionName = '';
                for (let j = 0; j < this.setsObjectForQuestions[sets[i]].length; j++) {
                    let qNo = '';

          if (this.setsObjectForQuestions[sets[i]][j].sectionNames) {
            sectionName = this.setsObjectForQuestions[sets[i]][j].sectionNames;
            const questionMark =
              this.setsObjectForQuestions[sets[i]][j].questionMarks;
            this.schema[sets[i]][`${sectionName}(${questionMark})`] = {
              validation: {
                type: "textLength",
              },
              variableName: sectionName,
            };
            data[i][sets[i]].push({
              [sectionName]: "",
            });
          }

          if (
            this.setsObjectForQuestions[sets[i]][j].questionNumber &&
            this.setsObjectForQuestions[sets[i]][j].questionNumber !== "OR"
          ) {
            qNo = this.setsObjectForQuestions[sets[i]][j].questionNumber;
            const questionMark =
              this.setsObjectForQuestions[sets[i]][j].questionMarks;
            this.schema[sets[i]][`${sectionName}_${qNo}(${questionMark})`] = {
              validation: {
                type: "textLength",
              },
              variableName: `${sectionName}_${qNo}`,
            };
            data[i][sets[i]].push({
              [`${sectionName}_${qNo}`]: "",
            });
          }

          if (
            this.setsObjectForQuestions[sets[i]][j].subQnNo &&
            this.setsObjectForQuestions[sets[i]][j].subQnNo !== "OR" &&
            (!this.setsObjectForQuestions[sets[i]][j].anyStatement ||
              (this.setsObjectForQuestions[sets[i]][j].anyStatement &&
                this.setsObjectForQuestions[sets[i]][j].question))
          ) {
            const questionMark =
              this.setsObjectForQuestions[sets[i]][j].questionMarks;
            this.schema[sets[i]][
              `${sectionName}_${
                this.setsObjectForQuestions[sets[i]][j].subQnNo
              }(${questionMark})`
            ] = {
              validation: {
                type: "textLength",
              },
              variableName: `${sectionName}_${
                this.setsObjectForQuestions[sets[i]][j].subQnNo
              }`,
            };
            data[i][sets[i]].push({
              [`${sectionName}_${
                this.setsObjectForQuestions[sets[i]][j].subQnNo
              }`]: "",
            });
          }
        }
      }
      const res = await generateJsonFromExcel(
        this.questionMarksExcelFile,
        this.schema,
        false
      );
      if (res.errors.length === 0) {
        try {
          const objectsArrayToUpdateMarks = [];
          const examMarks = [];
          const excelSheets = Object.keys(res.data);

                    for (const sheet of excelSheets) {
                        for (const data of res.data[sheet]) {
                            let student
                        if (data.prn || data.rollNumber || data.examSeatNumber) {
                          student = this.students.find(s => 
                                (s.PRN_No && data.prn && s.PRN_No === data.prn) ||
                                (s.rollNumber && data.rollNumber && s.rollNumber === data.rollNumber) ||
                                (s?.examSeatNumber && data?.examSeatNumber && s?.examSeatNumber === data?.examSeatNumber)
                              );
                            if (student) {
                                objectsArrayToUpdateMarks.push({
                                    uId: student.uId,
                                    instituteId: this.selectedInstitute,
                                    semId: this.selectedSemester.semId,
                                    examId: this.examId,
                                    status: 'Submitted',
                                    submittedOn: student?.submittedOn ? new Date(student?.submittedOn).toISOString() : new Date().toISOString(),
                                    subjectId: this.prop_selectedSubjectId,
                                    attemptNumber: student.attemptNumber,
                                    totalMarksObtained: 0,
                                    answers: [],
                                    marksGivenTo: data,
                                    setName: sheet
                                });
                            }
                        } else {
                                this.excelErrors.push('Roll Number and PRN are not present for ' + data.name)
                            }
                        }
                    }

                    for (let i = 0; i < objectsArrayToUpdateMarks.length; i++) {
                        const marksGivenToKeys = Object.keys(objectsArrayToUpdateMarks[i].marksGivenTo)
                        for (let j = 0; j < marksGivenToKeys.length; j++) {
                            const sectionAndQuestion = marksGivenToKeys[j].split('_')
                            if (sectionAndQuestion.length === 1) {
                                for (let k = 0; k < this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName].length; k++) {
                                    if (
                                        this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].sectionNames === sectionAndQuestion[0] &&
                                        this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionMarks >= objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion[0]]
                                    ) {
                                        objectsArrayToUpdateMarks[i].totalMarksObtained += objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion[0]]
                                        objectsArrayToUpdateMarks[i].answers.push({
                                            questionId: this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionId,
                                            obtainedMarks: objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion[0]]
                                        });
                                    } else if (this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].sectionNames === sectionAndQuestion[0] &&
                                        this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionMarks < objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion[0]]) {
                                        this.excelErrors.push('Given Marks of ' + this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].sectionNames + ':' + ' ' + objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion[0]] + ' are greater than its assigned marks')
                                    }
                                }
                            } else {
                                let secName = ''
                                for (let k = 0; k < this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName].length; k++) {
                                    if (this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k]?.sectionNames) secName = this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k]?.sectionNames
                                    if (secName === sectionAndQuestion[0] && (sectionAndQuestion[1] === this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionNumber || sectionAndQuestion[1] === this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].subQnNo) && this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionMarks >= objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion.join('_')]) {
                                        objectsArrayToUpdateMarks[i].answers.push({
                                            questionId: this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionId,
                                            obtainedMarks: objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion.join('_')]
                                        });
                                    } else if (secName === sectionAndQuestion[0] && (sectionAndQuestion[1] === this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionNumber || sectionAndQuestion[1] === this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].subQnNo) && this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionMarks < objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion.join('_')]) {
                                        this.excelErrors.push("Given Marks of " + secName + "-" + (this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionNumber ? this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].questionNumber : this.setsObjectForQuestions[objectsArrayToUpdateMarks[i].setName][k].subQnNo) + ':' + ' ' + objectsArrayToUpdateMarks[i].marksGivenTo[sectionAndQuestion.join('_')] + " are greater than its assigned marks");
                                    }
                                }
                            }
                        }
                        examMarks.push({
                            uId: objectsArrayToUpdateMarks[i].uId,
                            totalMarksObtained: objectsArrayToUpdateMarks[i].totalMarksObtained,
                            answers: objectsArrayToUpdateMarks[i].answers
                        })
                    }
                    this.createdQuestions.examMarks = examMarks
                    if (this.excelErrors.length === 0) {
                        await this.examSubjectAndQuestionRepositoryInstance.createExamSubjectQuestions(this.createdQuestions);
                        this.uploadQuestionViseMarksViaExcel = false
                        this.questionMarksExcelFile = null
                        await this.fetchData();
                        await this.StudentMarks()
                        // this.created()
                        showStatus("Marks updated successfully", 2000, 'success', this);
                    }
                } catch (err) {
                    console.error(err);
                    showStatus("Error updating marks", 1000, "error", this);
                }
            } else {
                this.excelErrors = res.errors
            }
        },
        downloadOverallMarksReport() {
            const data = []
            const sets = Object.keys(this.setsObjectForQuestions)
            for (let i = 0; i < sets.length; i++) {
                data.push({ [sets[i]]: [] });
                for (let j = 0; j < this.students?.length; j++) {
                    const objToPush = {}
                    objToPush.RollNo = this.students[j]?.rollNumber
                    if(this.isStmiras) {
                      objToPush.ExamSeatNo = this.students[j]?.examSeatNumber
                    }
                    objToPush.PRN = this.students[j]?.PRN_No
                    objToPush.Name = this.students[j]?.name
                    objToPush[`Overall_Marks(${this.totalMarks})`] = this.students[j]?.totalMarksObtained === 0 ? '' : this.students[j]?.totalMarksObtained
                    data[i][sets[i]].push(objToPush);
                }
            }
            createExcelThroughJsonForMultipleSheets(data, this.exam.title +'_'+ this.prop_selectedSubject+ '_Marks')
        },
        async startUploadingOverallMarksExcel() {
            this.schema = {}
            this.loading = true
            const data = []
            this.excelErrors = []
            const sets = Object.keys(this.setsObjectForQuestions)
            for (let i = 0; i < sets.length; i++) {
                data.push({ [sets[i]]: [] })
                this.schema[sets[i]] = {
                    RollNo: {
                        validation: {
                        type: 'textLength'
                        },
                        variableName: 'rollNumber'
                    },
                    // ExamSeatNo: {
                    //     validation: {
                    //     type: 'textLength'
                    //     },
                    //     variableName: 'examSeatNumber'
                    // },
                    PRN: {
                        validation: {
                            type: 'textLength'
                        },
                        variableName: 'prn'
                    },
                    Name: {
                        validation: {
                            type: 'textLength',
                            required: true
                        },
                        variableName: 'name'
                    },
                    [`Overall_Marks(${this.totalMarks})`]: {
                        validation: {
                            type: 'textLength',
                            required: true
                        },
                        variableName: 'overall_marks'
                    }
                }
                if(this.isStmiras) {
                  this.schema[sets[i]].ExamSeatNo = {
                        validation: {
                        type: 'textLength'
                        },
                        variableName: 'examSeatNumber'
                    }
                }
            }
            const res = await generateJsonFromExcel(this.questionMarksExcelFile, this.schema, false);
        
            if (res.errors.length === 0) {
                try {
                    const examMarks = [];
                    const excelSheets = Object.keys(res.data);

                    for (const sheet of excelSheets) {
                        for (const data of res.data[sheet]) {
                            let student
                            if (data.prn || data.rollNumber || data.examSeatNumber) {
                              student = this.students.find(s => 
                              (s.PRN_No && data.prn && s.PRN_No === data.prn) ||
                                (s.rollNumber && data.rollNumber && s.rollNumber === data.rollNumber) ||
                                (s?.examSeatNumber && data?.examSeatNumber && s?.examSeatNumber === data?.examSeatNumber)
                              );
                            if (student) {
                                if (this.totalMarks < data.overall_marks) {
                                    this.excelErrors.push('Marks given to ' + student.name + ' should not be greater than ' + this.totalMarks)
                                } else {
                                    examMarks.push({
                                        uId: student.uId,
                                        totalMarksObtained: data.overall_marks + '',
                                        answers: []
                                    });
                                }
                            } 
                        } else {
                            this.excelErrors.push('Roll Number and PRN are not present for ' + data.name)
                        }
                        }
                    }
                    this.createdQuestions.examMarks = examMarks
                    if (this.excelErrors.length === 0) {
                        await this.examSubjectAndQuestionRepositoryInstance.createExamSubjectQuestions(
                            this.createdQuestions
                        );
                        this.uploadOverallMarksViaExcel = false
                        this.questionMarksExcelFile = null
                        await this.fetchData();
                        await this.StudentMarks()
                        showStatus("Marks updated successfully", 2000, 'success', this);
                    }
                } catch (err) {
                    console.error(err);
                    showStatus("Error updating marks", 1000, "error", this);
                }
            } else {
                this.excelErrors = res.errors
            }
           
        },
        async startUploadingSectionMarksExcel() {
            this.schema = {}
            this.loading = true
            const data = []
            this.excelErrors = []
            const sets = Object.keys(this.setsObjectForQuestions)
            for (let i = 0; i < sets.length; i++) {
                data.push({ [sets[i]]: [] })
                this.schema[sets[i]] = {
                    RollNo: {
                        validation: {
                        type: 'textLength'
                        },
                        variableName: 'rollNumber'
                    },
                    // ExamSeatNo: {
                    //     validation: {
                    //     type: 'textLength'
                    //     },
                    //     variableName: 'examSeatNumber'
                    // },
                    PRN: {
                        validation: {
                            type: 'textLength'
                        },
                        variableName: 'prn'
                    },
                    Name: {
                        validation: {
                            type: 'textLength',
                            required: true
                        },
                        variableName: 'name'
                    }
                }
                if(this.isStmiras) {
                  this.schema[sets[i]].ExamSeatNo = {
                        validation: {
                        type: 'textLength'
                        },
                        variableName: 'examSeatNumber'
                    }
                }
                for (let k = 0; k < this.students.length; k++) {
                    data[i][sets[i]].push({ PRN: this.students[k].PRN_No, Name: this.students[k].name })
                }
                let sectionName = '';
                for (let j = 0; j < this.setsObjectForQuestions[sets[i]].length; j++) {
                    if (this.setsObjectForQuestions[sets[i]][j].sectionNames) {
                        sectionName = this.setsObjectForQuestions[sets[i]][j].sectionNames;
                        const questionMark = this.setsObjectForQuestions[sets[i]][j].questionMarks;
                        this.schema[sets[i]][`${sectionName}(${questionMark})`] = {
                            validation: {
                                type: 'textLength'
                            },
                            variableName: sectionName
                        }
                        data[i][sets[i]].push({
                            [sectionName]: ''
                        });
                    }
                }
            }
            const res = await generateJsonFromExcel(this.questionMarksExcelFile, this.schema, false);
      
            if (res.errors.length === 0) {
                try {
                    const objectsArrayToUpdateMarks = [];
                    let examMarks = []
                    const excelSheets = Object.keys(res.data);

                    for (const sheet of excelSheets) {
                        for (const data of res.data[sheet]) {
                            let student;
                        if (data.prn || data.rollNumber || data.examSeatNumber) {
                          student = this.students.find(s => 
                          (s.PRN_No && data.prn && s.PRN_No === data.prn) ||
                                (s.rollNumber && data.rollNumber && s.rollNumber === data.rollNumber) ||
                                (s?.examSeatNumber && data?.examSeatNumber && s?.examSeatNumber === data?.examSeatNumber)
                              );
                            if (student) {
                                objectsArrayToUpdateMarks.push({
                                    uId: student.uId,
                                    instituteId: this.selectedInstitute,
                                    semId: this.selectedSemester.semId,
                                    examId: this.examId,
                                    status: 'Submitted',
                                    submittedOn: student?.submittedOn ? new Date(student?.submittedOn).toISOString() : new Date().toISOString(),
                                    subjectId: this.prop_selectedSubjectId,
                                    attemptNumber: student.attemptNumber,
                                    totalMarksObtained: 0,
                                    answers: [],
                                    marksGivenTo: data,
                                    setName: sheet
                                });
                            }
                        } else {
                            this.excelErrors.push('Roll Number and PRN are not present for ' + data.name)
                        }
                        }
                    }

          for (let i = 0; i < objectsArrayToUpdateMarks.length; i++) {
            const marksGivenToKeys = Object.keys(
              objectsArrayToUpdateMarks[i].marksGivenTo
            );
            for (let j = 0; j < marksGivenToKeys.length; j++) {
              const sectionAndQuestion = marksGivenToKeys[j].split("_");
              if (sectionAndQuestion.length === 1) {
                for (
                  let k = 0;
                  k <
                  this.setsObjectForQuestions[
                    objectsArrayToUpdateMarks[i].setName
                  ].length;
                  k++
                ) {
                  if (
                    this.setsObjectForQuestions[
                      objectsArrayToUpdateMarks[i].setName
                    ][k].sectionNames === sectionAndQuestion[0] &&
                    this.setsObjectForQuestions[
                      objectsArrayToUpdateMarks[i].setName
                    ][k].questionMarks >=
                      objectsArrayToUpdateMarks[i].marksGivenTo[
                        sectionAndQuestion[0]
                      ]
                  ) {
                    objectsArrayToUpdateMarks[i].totalMarksObtained +=
                      objectsArrayToUpdateMarks[i].marksGivenTo[
                        sectionAndQuestion[0]
                      ];
                    objectsArrayToUpdateMarks[i].answers.push({
                      questionId:
                        this.setsObjectForQuestions[
                          objectsArrayToUpdateMarks[i].setName
                        ][k].questionId,
                      obtainedMarks:
                        objectsArrayToUpdateMarks[i].marksGivenTo[
                          sectionAndQuestion[0]
                        ],
                    });
                  } else if (
                    this.setsObjectForQuestions[
                      objectsArrayToUpdateMarks[i].setName
                    ][k].sectionNames === sectionAndQuestion[0] &&
                    this.setsObjectForQuestions[
                      objectsArrayToUpdateMarks[i].setName
                    ][k].questionMarks <
                      objectsArrayToUpdateMarks[i].marksGivenTo[
                        sectionAndQuestion[0]
                      ]
                  ) {
                    this.excelErrors.push(
                      "Given Marks of " +
                        this.setsObjectForQuestions[
                          objectsArrayToUpdateMarks[i].setName
                        ][k].sectionNames +
                        ":" +
                        " " +
                        objectsArrayToUpdateMarks[i].marksGivenTo[
                          sectionAndQuestion[0]
                        ] +
                        " are greater than its assigned marks"
                    );
                  }
                }
              }
            }
            examMarks.push({
              uId: objectsArrayToUpdateMarks[i].uId,
              totalMarksObtained:
                objectsArrayToUpdateMarks[i].totalMarksObtained,
              answers: objectsArrayToUpdateMarks[i].answers,
            });
          }
          this.createdQuestions.examMarks = examMarks;
          if (this.excelErrors.length === 0) {
            await this.examSubjectAndQuestionRepositoryInstance.createExamSubjectQuestions(
              this.createdQuestions
            );
            this.uploadSectionMarksViaExcel = false;
            this.questionMarksExcelFile = null;
            await this.fetchData();
            await this.StudentMarks();
            showStatus("Marks updated successfully", 2000, "success", this);
          }
        } catch (err) {
          console.error(err);
          showStatus("Error updating marks", 1000, "error", this);
        }
      } else {
        this.excelErrors = res.errors;
      }
    },
    async resubmitAssignment(item) {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          uId: item.uId,
          examId: this.examId,
          attemptNumber: item.attemptNumber,
          totalMarksObtained: 0,
          status: "Not Submitted",
          subjectId: this.prop_selectedSubjectId,
          answers: item.answers,
          resubmit: true,
        };
        await this.assignmentUserRepositoryInstance.updateMarksObtainedOfAnUserForAnAssignment(
          objToPush
        );
        this.students = this.students.map((student) => {
          if (
            student.uId === item.uId &&
            student.attemptNumber === item.attemptNumber
          ) {
            return {
              ...student,
              submittedStatus: "Not Submitted",
              resubmit: false,
            };
          }
          return student;
        });
        // showStatus("Marks updated successfully", 2000, 'success', this);
      } catch (err) {
        console.error(err);
        showStatus("Error updating marks", 1000, "error", this);
      }
    },
    setActiveSet(set) {
      this.setsObjectForQuestions[this.activeSet.setName] = this.questionsArray;
      this.activeSet = set;
      this.questionsArray = this.setsObjectForQuestions[this.activeSet.setName];
    },
    getAllSections() {
      this.allSections = [];
      for (let i = 0; i < this.createdQuestions?.allQuestions.length; i++) {
        if (this.createdQuestions?.allQuestions[i].questionType === "Set") {
          for (
            let j = 0;
            j < this.createdQuestions.allQuestions[i].question.length;
            j++
          ) {
            this.allSections.push({
              questionId:
                this.createdQuestions.allQuestions[i].question[j].questionId,
              sectionName:
                this.createdQuestions.allQuestions[i].question[j]
                  .questionStatement,
              obtainedMarks: null,
              questionMarks:
                this.createdQuestions.allQuestions[i].question[j].questionMarks,
            });
          }
        }
      }
      for (let i = 0; i < this.students.length; i++) {
        this.students[i].allSections = this.allSections;
      }
    },
    getQuestionsOfSelectedSet(set, item) {
      this.studentData = item;
      let anyQuestionFound = false;
      let setQuestions = [];
      for (let i = 0; i < this.createdQuestions.allQuestions.length; i++) {
        if (
          this.createdQuestions.allQuestions[i].questionType === "Set" &&
          this.createdQuestions.allQuestions[i].questionStatement ===
            set.setName
        ) {
          setQuestions = this.createdQuestions.allQuestions[i].question;
        }
      }
      this.seperatedSetQuestions = [];

            for (let i = 0; i < setQuestions?.length; i++) {
                anyQuestionFound = false
                this.seperatedSetQuestions.push({
                    sectionNames: setQuestions[i].questionNumber,
                    question: setQuestions[i].questionStatement,
                    questionId: setQuestions[i].questionId,
                    questionMarks: setQuestions[i].questionMarks,
                    selectedCos: setQuestions[i]?.cos,
                    cos: this.coNames,
                    selectedBlooms: setQuestions[i].blooms,
                    blooms: this.bloomsNames
                })
                if (setQuestions[i].question?.length > 0) {
                    const sectionQuestion = setQuestions[i].question
                    for (let j = 0; j < sectionQuestion?.length; j++) {
                        if (sectionQuestion[j].questionType === 'OrQuestion') {
                            anyQuestionFound = false
                            this.seperatedSetQuestions.push({
                                questionNumber: 'OR'
                            })
                        }
                        anyQuestionFound = false
                        this.seperatedSetQuestions.push({
                            questionNumber: sectionQuestion[j]?.questionNumber,
                            question: sectionQuestion[j]?.questionStatement === 'Solve any_out of' ? 'Solve any (' + sectionQuestion[j].NumberOfQuestionsToSolve + ')' : sectionQuestion[j].questionStatement,
                            tryl: sectionQuestion[j]?.NumberOfQuestionsToSolve,
                            anyCount: sectionQuestion[j]?.NumberOfQuestionsToSolve,
                            outOfCount: sectionQuestion[j]?.question?.length,
                            solveAnyCount: sectionQuestion[j]?.questionStatement === 'Solve any_out of' ? 0 : sectionQuestion[j]?.NumberOfQuestionsToSolve,
                            questionId: sectionQuestion[j]?.questionId,
                            questionMarks: sectionQuestion[j]?.questionMarks,
                            selectedCos: sectionQuestion[j]?.cos,
                            cos: this.coNames,
                            selectedBlooms: sectionQuestion[j]?.blooms,
                            blooms: this.bloomsNames
                        })
                        if (sectionQuestion[j].NumberOfQuestionsToSolve) {
                            anyQuestionFound = true
                            this.seperatedSetQuestions.push({
                                subQnNo: 'Any ' + sectionQuestion[j].NumberOfQuestionsToSolve,
                                anyCount: sectionQuestion[j].NumberOfQuestionsToSolve,
                                anyStatement: true
                            })
                        }
                        if (sectionQuestion[j]?.question?.length > 0) {
                            const subQuestion = sectionQuestion[j]?.question
                            for (let k = 0; k < subQuestion.length; k++) {
                                if (subQuestion[k].questionType === 'OrSubQuestion') {
                                    this.seperatedSetQuestions.push({
                                        subQnNo: 'OR',
                                        anyStatement: false
                                    })
                                }
                                if (anyQuestionFound) {
                                    this.seperatedSetQuestions.push({
                                        subQnNo: subQuestion[k].questionNumber,
                                        anySubQuestion: true,
                                        anyStatement: true,
                                        question: subQuestion[k].questionStatement,
                                        questionId: subQuestion[k]?.questionId,
                                        questionMarks: subQuestion[k].questionMarks,
                                        selectedCos: subQuestion[k].cos,
                                        cos: this.coNames,
                                        selectedBlooms: subQuestion[k].blooms,
                                        blooms: this.bloomsNames
                                    })
                                } else {
                                    anyQuestionFound = false
                                    this.seperatedSetQuestions.push({
                                        subQnNo: subQuestion[k].questionNumber,
                                        anyStatement: false,
                                        question: subQuestion[k].questionStatement,
                                        questionId: subQuestion[k]?.questionId,
                                        questionMarks: subQuestion[k].questionMarks,
                                        selectedCos: subQuestion[k].cos,
                                        cos: this.coNames,
                                        selectedBlooms: subQuestion[k].blooms,
                                        blooms: this.bloomsNames
                                    })
                                }
                            }
                        }
                    }
                }
            }
            // this.questionWiseMarksDialog = true
            if (!set.forShowingQuestions && this.studentData?.answers) {
                const studentDataMap = new Map(this.studentData?.answers.map(data => [data.questionId, data]));
                for (const setQuestion of this.seperatedSetQuestions) {
                    const studentDataForQuestion = studentDataMap.get(setQuestion.questionId);
                    if (studentDataForQuestion) {
                        setQuestion.assignMarks = studentDataForQuestion.obtainedMarks;
                    }
                }
                this.questionWiseMarksDialog = true
            } else return this.seperatedSetQuestions
        },
        getSetSections(selectedSet) {
            this.sectionsOfSelectedSet = []
            for (let i = 0; i < this.setsObjectForQuestions[selectedSet.setName].length; i++) {
                if (this.setsObjectForQuestions[selectedSet.setName][i].sectionNames) {
                    this.sectionsOfSelectedSet.push(this.setsObjectForQuestions[selectedSet.setName][i].question)
                }
            }
        },
        async updateMarksOfAStudent(item, limit) {
            this.marksUpdated = true
            if (item.totalMarksObtained > limit) {
                showStatus("Invalid marks entered", 1000, "error", this);
            } else {
                if (item.totalMarksObtained !== "") {
                    try {
                        let foundStudent = false
                        for (let i = 0; i < this.createdQuestions.examMarks.length; i++) {
                            if (this.createdQuestions.examMarks[i].uId === item.uId) {
                                foundStudent = true
                                this.createdQuestions.examMarks[i].totalMarksObtained = item.totalMarksObtained
                                this.createdQuestions.examMarks[i].answers = [{ questionId: item.selectedSet?.questionId, obtainedMarks: item?.totalMarksObtained }]
                            }
                        }
                        if (!foundStudent) {
                            this.createdQuestions.examMarks.push({
                                uId: item.uId,
                                totalMarksObtained: item?.totalMarksObtained,
                                answers: [{ questionId: item.selectedSet?.questionId, obtainedMarks: item?.totalMarksObtained }]
                            })
                        }
                        this.students[this.students.indexOf(item)].totalMarksObtained = item.totalMarksObtained
                    } catch (err) {
                        console.error(err);
                        showStatus("Error updating marks", 1000, "error", this);
                    }
                }
            }
        //    await this.fetchData();
        },
        async sectionWiseStudentMarks(item, secName) {
            const answersArray = []
            let totalMarksObtained = 0
            for (let i = 0; i < this.allSections.length; i++) {
                if (item[this.allSections[i].sectionName]) {
                    answersArray.push({
                        questionId: this.allSections[i].questionId,
                        obtainedMarks: item[this.allSections[i].sectionName]
                    })
                    totalMarksObtained += (item[this.allSections[i].sectionName] - '')
                }
            }
            if (totalMarksObtained > this.totalMarks) {
                showStatus("Given Section Marks can't be greater than Total assignment Marks", 1000, "error", this);
            } else {
                try {
                    let foundStudent = false
                    for (let i = 0; i < this.createdQuestions.examMarks.length; i++) {
                        if (this.createdQuestions.examMarks[i].uId === item.uId) {
                            foundStudent = true
                            this.createdQuestions.examMarks[i].totalMarksObtained = totalMarksObtained
                            this.createdQuestions.examMarks[i].answers = answersArray
                        }
                    }
                    if (!foundStudent) {
                        this.createdQuestions.examMarks.push({
                            uId: item.uId,
                            totalMarksObtained: totalMarksObtained,
                            answers: answersArray
                        })
                    }
                    await this.examSubjectAndQuestionRepositoryInstance.createExamSubjectQuestions(
                        this.createdQuestions
                    );
                    this.students[this.students.indexOf(item)].totalMarksObtained = item.totalMarksObtained
                    showStatus("Marks updated successfully", 2000, 'success', this);
                } catch (err) {
                    console.error(err);
                    showStatus("Error updating marks", 1000, "error", this);
                }
            }
            // await this.fetchData();
            this.StudentMarks()
            // }
        },
        addSubquestionsOfAny(outOfCount, anyCount, questionMarks, index) {
            const qMarks = questionMarks / anyCount
            const quesObject = {
                subQnNo: 'Any ' + anyCount,
                anyQuestionId: v4(),
                questionNumberAny: 'Any ' + outOfCount
                // questionNumber: 'Any ' + anyCount
            }
            this.questionsArray.splice(index, 0, quesObject)
            index++

      for (let i = index; i < this.questionsArray.length; i++) {
        if (this.questionsArray[i]?.questionNo) {
          break;
        } else if (
          this.questionsArray[i].anyQuestionId ||
          this.questionsArray[i].anySubQuestionId
        ) {
          this.questionsArray.splice(i, 1);
          i--;
        }
      }

            // this.questionsArray.push(quesObject)
            let subQueCount = 0
            for (let i = 0; i < outOfCount; i++) {
                // index++
                const obj = {
                    question: '',
                    anySubQuestionId: v4(),
                    anyCount: anyCount,
                    subQnNo: 'Q' + this.questionNo + '.' + ++subQueCount,
                    anyQue: true,
                    questionMarks: qMarks,
                    cos: this.coNames,
                    blooms: this.bloomsNames
                }
                this.questionsArray.splice(index, 0, obj)
                this.resetQueNo()
            }
        },
        validateGivenSectionWiseMarks() {
            let totalSectionMarks = 0
            if (this.seperatedSetQuestions.length > 0) {
                for (let i = 0; i < this.seperatedSetQuestions.length; i++) {
                    if (this.seperatedSetQuestions[i]?.sectionNames) {
                        totalSectionMarks += (this.seperatedSetQuestions[i]?.assignMarks - '')
                    }
                }
                if (totalSectionMarks > this.totalMarks) {
                    showStatus("Section marks should be equal or less to the total marks of assignment", 5000, "error", this);
                    this.questionsValidationSuccess = false
                } else {
                    this.validateGivenQuestionUnderSectionWiseMarks()
                }
            } else {
                showStatus("Questions are not added yet", 5000, "error", this);
                this.questionsValidationSuccess = false
            }
        },
        validateGivenQuestionUnderSectionWiseMarks(set) {
            let totalQuestionUnderSectionMarks = 0
            let sectionMarks = 0
            let areQuestionPresent = false
            let checkNext = true
            for (let i = 0; i < this.seperatedSetQuestions.length; i++) {
                if (this.seperatedSetQuestions[i]?.sectionNames) {
                    if (totalQuestionUnderSectionMarks > sectionMarks && areQuestionPresent) {
                        showStatus(`Total questions under ${this.seperatedSetQuestions[i].sectionNames} should be equal or less to the marks assigned to it`, 5000, "error", this);
                        checkNext = false
                        this.questionsValidationSuccess = false
                        break
                    }
                    sectionMarks = (this.seperatedSetQuestions[i]?.assignMarks - '')
                    totalQuestionUnderSectionMarks = 0
                } else if (this.seperatedSetQuestions[i]?.questionNumber && this.seperatedSetQuestions[i]?.questionNumber !== 'OR') {
                    totalQuestionUnderSectionMarks += (this.questionsArray[i]?.assignMarks - '')
                    areQuestionPresent = true
                } else if (this.seperatedSetQuestions[i]?.questionNumber && this.seperatedSetQuestions[i]?.questionNumber === 'OR') {
                    totalQuestionUnderSectionMarks -= (this.seperatedSetQuestions[i + 1]?.assignMarks - '')
                }
            }
            if (totalQuestionUnderSectionMarks > sectionMarks && areQuestionPresent) {
                checkNext = false
                showStatus(`Total questions under section should be equal or less to the marks assigned to it`, 5000, "error", this);
                checkNext = false
                this.questionsValidationSuccess = false
            }
            if (checkNext) {
                this.validateGivenSubQUestionUnderQuestionUnderSectionWiseMarks()
            }
        },
        validateGivenSubQUestionUnderQuestionUnderSectionWiseMarks() {
            let totalSubQuestionUnderQuestionUnderSectionMarks = 0
            let assignMarks = 0
            let areSubQuestionsAvailable = false
            let saveQuestions = true
            for (let i = 0; i < this.seperatedSetQuestions.length; i++) {
                if (this.seperatedSetQuestions[i]?.questionNumber) {
                    if (totalSubQuestionUnderQuestionUnderSectionMarks > assignMarks && areSubQuestionsAvailable) {
                        showStatus(`Total sub questions marks under ${this.seperatedSetQuestions[i].question} should be equal or less to the marks assigned to it`, 5000, "error", this);
                        saveQuestions = false
                        this.questionsValidationSuccess = false
                        break
                    }
                    assignMarks = (this.seperatedSetQuestions[i]?.assignMarks - '')
                    totalSubQuestionUnderQuestionUnderSectionMarks = 0
                } else if (this.seperatedSetQuestions[i]?.subQnNo && this.seperatedSetQuestions[i]?.subQnNo !== 'OR' && !this.seperatedSetQuestions[i]?.questionNumberAny && !this.seperatedSetQuestions[i]?.anySubQuestionId) {
                    totalSubQuestionUnderQuestionUnderSectionMarks += (this.seperatedSetQuestions[i].assignMarks - '')
                    areSubQuestionsAvailable = true
                } else if (this.seperatedSetQuestions[i]?.subQnNo && this.seperatedSetQuestions[i]?.subQnNo === 'OR') {
                    totalSubQuestionUnderQuestionUnderSectionMarks -= (this.seperatedSetQuestions[i + 1].assignMarks - '')
                }
            }
            if (totalSubQuestionUnderQuestionUnderSectionMarks > assignMarks && areSubQuestionsAvailable) {
                saveQuestions = false
                showStatus(`Total sub questions marks under question should be equal or less to the marks assigned to it`, 5000, "error", this);
                saveQuestions = false
                this.questionsValidationSuccess = false
            }
            if (saveQuestions) {
                this.saveQuestionWiseMarks()
            }
        },
        async saveQuestionWiseMarks() {
            let marksIncorrect = false
            let answers = []
            let totalMarksObtained = 0
            try {
                for (let i = 0; i < this.seperatedSetQuestions.length; i++) {
                    if (this.seperatedSetQuestions[i].assignMarks) {
                        if (Number(this.seperatedSetQuestions[i].assignMarks) > Number(this.seperatedSetQuestions[i].questionMarks)) {
                            marksIncorrect = true
                            showStatus(`Given Marks can not be greater than question marks`, 5000, "error", this);
                        } else {
                            answers.push({
                                questionId: this.seperatedSetQuestions[i]?.questionId,
                                obtainedMarks: this.seperatedSetQuestions[i].assignMarks
                            })
                            totalMarksObtained += this.seperatedSetQuestions[i].sectionNames ? (this.seperatedSetQuestions[i].assignMarks - '') : 0
                        }
                    }
                }
                let foundStudent = false
                    for (let i = 0; i < this.createdQuestions.examMarks.length; i++) {
                        if (this.createdQuestions.examMarks[i].uId === this.studentForQuestionWiseMarks.uId) {
                            foundStudent = true
                            this.createdQuestions.examMarks[i].totalMarksObtained = totalMarksObtained
                            this.createdQuestions.examMarks[i].answers = answers
                        }
                    }
                    if (!foundStudent) {
                        this.createdQuestions.examMarks.push({
                            uId: this.studentForQuestionWiseMarks.uId,
                            totalMarksObtained: totalMarksObtained,
                            answers: answers
                        })
                    }
                if (!marksIncorrect) {
                    await this.examSubjectAndQuestionRepositoryInstance.createExamSubjectQuestions(
                        this.createdQuestions
                    );
                    this.students[this.students.indexOf(this.studentData)].totalMarksObtained = this.studentData.totalMarksObtained
                    // this.students[this.students.indexOf(this.studentData)].submittedOn = convertDateTime(objToPush.submittedOn);
                    showStatus("Marks updated successfully", 2000, 'success', this);
                    // this.StudentMarks()
                    this.questionWiseMarksDialog = false
                }
            } catch (err) {
                console.error(err);
                showStatus("Error updating marks", 1000, "error", this);
            }
        //    await this.fetchData();
           this.StudentMarks()
        },
        goBack() {
            this.$router.push({
                name: 'activityManageExamsRevamped',
                params: {
                    prop_tabNumber: 5
                }
            })
        }
    }
}

</script>

<style src="./examStudentMarks.css"></style>