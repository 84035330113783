export default {
    createExamSubjectQuestions: '/examSubjectAndQuestions/createExamSubjectQuestions',
    bulkCreateExamSubjectQuestions: '/examSubjectAndQuestions/bulkCreateExamSubjectQuestions',
    generateExamQuestionsExcel: '/excel/examQuestionsExcel/generateExamQuestionsExcel',
    parseExcel: '/excel/examQuestionsExcel/parseExcel',
    getExamSubjectQuestions: '/examSubjectAndQuestions/getExamSubjectQuestions',
    getExamMultipleSubjectQuestions: '/examSubjectAndQuestions/getExamMultipleSubjectQuestions',
    getMultipleExam: '/examSubjectAndQuestions/getMultipleExam',
    updateMultipleSubjectExamMarks: '/examSubjectAndQuestions/updateMultipleSubjectExamMarks',
}
